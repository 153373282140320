.manga-viewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 60px;  // Ensure there's room for the header
  box-sizing: border-box;
  overflow-y: auto;    // Allow vertical scrolling instead of hiding overflow
}

.page-container {
  width: 100%;
  max-width: 100vw;
  // Remove or adjust overflow if needed here
}

// Optionally, add a class for the button to ensure it isn't overlapped
.load-previous-btn {
  position: relative;
  margin-bottom: 10px; // adjust spacing as needed
  z-index: 1100;       // slightly above the header's default if necessary
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
}

.header-button {
  padding: 8px 16px;
  margin-right: 10px;
  cursor: pointer;
}

.profile-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.profile-menu {
  position: absolute;
  right: 20px;
  top: 60px;
  background: white;
  list-style: none;
  padding: 5px 0;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  border-radius: 5px;
}

.profile-menu li {
  padding: 10px 20px;
  cursor: pointer;
}

