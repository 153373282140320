.folderCardContainer {
  width: 250px;
  /* spacing is handled by the parent grid's gap */
  position: relative;
}

.folderCard {
  position: relative;
  width: 100%;
  height: 350px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.folderCard:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.folderCoverImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.folderTitle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
  padding: 10px;
  box-sizing: border-box;
  z-index: 2;
  text-align: center;
}

.folderTitle h2 {
  margin: 0;
  font-size: 1.1em;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editIcon,
.deleteIcon {
  position: absolute;
  width: 24px;
  height: 24px;
  z-index: 3;
  cursor: pointer;
}

.editIcon {
  top: 10px;
  right: 40px;
}

.deleteIcon {
  top: 10px;
  right: 10px;
}

.progressOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 4;
}

.progressBar {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.5s ease;
  border-radius: 0 0 8px 8px;
}
