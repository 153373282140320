.folderManager {
  padding: 20px;
  background-color: #f0f2f5;
  min-height: 100vh;
}

.folderGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* space between folder cards */
  justify-content: center;
}
